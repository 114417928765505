import { getInfoShinhanApi } from "apis/shinhanApi";
import types from "./types";

const getInfoShinhan = () => async (dispatch: any) => {
  try {
    const { data }: any = await getInfoShinhanApi();

    if (data) {
      dispatch({
        type: types.GET_DATA_SHINHAN,
        dataShinhan: data,
      });
    }
  } catch (error) {}
};
export default { getInfoShinhan };
