import userActions from "./userActions";
import * as searchAction from "./searchAction";
import * as filterLeftMenuAction from "./filterLeftMenuAction";
import * as orderArtAction from "./orderArtAction";
import * as selectAction from "./selectActions";
import getUserAction from "./getUserAction";
import getCategoriesMarket from "./getCategoriesMarket";
import getCategoriesTerm from "./getCategoriesTerm";
import * as typeLoginSnsAction from "./typeLoginSnsAction";
import getInfoShinhan from "./getInfoShinhan";

const rootAction = {
  userActions,
  searchAction,
  filterLeftMenuAction,
  orderArtAction,
  selectAction,
  getUserAction,
  getCategoriesMarket,
  getCategoriesTerm,
  typeLoginSnsAction,
  getInfoShinhan,
};

export default rootAction;
