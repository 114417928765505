import { mainAxios } from "libs/axios";

export const getInfoShinhanApi = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/shinhan/my-balance`,
    requiresToken: true,
    notError: true,
    getError: true,
  });
};
