import React, { useEffect, useState } from "react"; //lazy
// import { useHistory } from "react-router-dom";

/** Components */
import { ErrorSpan, ProgressBar, Select } from "components";

import ImgAnswer from "assets/images/_group_sale/answer.png";

/** Style Local */
import { Empty, Input, InputNumber } from "antd";
import { UrlInternal } from "common/constants/endpoints";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moneyConvertLang from "utils/moneyConvertLang";
import * as S from "./style";
import moment from "moment";
import moneyConvert from "utils/moneyConvert";
import { formatPrice } from "utils/formatPrice";
import SelectInputAndPick from "components/SelectInputAndPick";

import { useCountdown } from "hooks/useCountdown";
import { useCountdownDetailGp } from "hooks/useCountdownDetailGp";
import InputNumberCustom from "components/InputNumberCustom";
import InputNumberNew from "components/InputNumberNew";
import Countdown from "react-countdown";
import { useSelector } from "react-redux";

interface Props {
  artName?: string;
  artProgress?: number;
  artTotalAmount?: number;
  artTargetAmount?: number;
  artTimeRemaining?: number;
  actionButtonDisable?: boolean;
  actionButtonTitle?: string;
  actionButtonEvent?: () => void;
  listSubImage?: any;
  goToDetail?: () => void;
  notButton?: boolean;
  errorContent?: string;
  maxStock?: number;
  maxUnit?: number;
  getQuantity?: any;
  pageSaleEnd?: boolean;
  waitingOrder?: boolean;
  eventType?: string;
  eventEndAt?: string;
  endAt?: string;
  startAt?: string;
  artTimeRemainingEvent?: number;
  artTimeRemainingV3?: number;
  data?: any;
  getTypeBuyGp?: (e: string) => void;
}

function CardDetailOnGoing({
  artName,
  artProgress = 0,
  artTotalAmount = 0,
  artTargetAmount = 0,
  artTimeRemaining = 0,
  actionButtonDisable = false,
  actionButtonTitle,
  actionButtonEvent,
  listSubImage = [],
  goToDetail,
  notButton,
  errorContent,
  maxStock = 0,
  getQuantity,
  maxUnit,
  pageSaleEnd,
  waitingOrder,
  eventType,
  eventEndAt,
  endAt,
  artTimeRemainingEvent = 0,
  artTimeRemainingV3 = 0,
  startAt,
  data,
  getTypeBuyGp,
}: Props) {
  const history = useHistory();
  const [currentItem, setCurrentItem] = useState<any>({
    id: 0,
    data: listSubImage[0] ?? "",
  });
  const { t } = useTranslation();
  const [qty, setQty] = useState(1);

  const [days, hours, minutes, seconds] = useCountdownDetailGp(
    new Date(data.endNormalBuyAt ?? new Date()).getTime()
  );

  const dataCountDown = useCountdown(
    new Date(endAt ?? new Date()).getTime(),
    artTimeRemaining
  );

  const [timeComplete, setTimeComplete] = useState(false);
  const { dataShinhan } = useSelector((state: any) => state.dataShinhan);

  // const timeComplete = days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0;

  const checkDisabledSelect =
    (!data?.canOrderNormal && !data?.canOrderWaiting) ||
    timeComplete ||
    (data?.gpVer === "V3"
      ? data?.stockForBuy
      : data?.canOrderWaiting
      ? data?.maxUnitForU1
      : data?.stockForBuy,
    data?.maxUnitForU1 <= 0);

  useEffect(() => {
    setCurrentItem({
      id: 0,
      data: listSubImage[0] ?? "",
    });
  }, [listSubImage]);

  const prefixO = (dataNumber: number) => {
    return dataNumber < 10 ? `0${dataNumber}` : dataNumber;
  };
  useEffect(() => {
    if (
      dataCountDown[0] <= 0 &&
      dataCountDown[1] <= 0 &&
      dataCountDown[2] <= 0 &&
      dataCountDown[3] <= 0 &&
      !pageSaleEnd
    ) {
      history.push(UrlInternal.GROUP_SALE_END);
    }
  }, [dataCountDown, pageSaleEnd]);

  const rendererIsComming = ({
    hours,
    minutes,
    seconds,
    completed,
    days,
  }: any) => {
    if (completed) {
      return "";
    } else {
      return (
        <div className="time">
          <span>마감까지</span>{" "}
          <span className="time-countdown">
            {days}일 {prefixO(hours)}시간 {prefixO(minutes)}분{" "}
            {prefixO(seconds)}초
          </span>{" "}
          <span>남았습니다.</span>
        </div>
      );
    }
  };

  const handleShowCountDownIsComming = () => {
    return (
      <Countdown
        date={new Date(data.endNormalBuyAt ?? new Date()).getTime()}
        renderer={rendererIsComming}
        onComplete={() => setTimeComplete(true)}
      />
    );
  };

  return (
    <S.SaleTogetherContainer className="sale-group">
      <S.ListImage>
        {/* <div> */}
        {listSubImage?.map((v: any, i: number) => (
          <S.ItemtImage
            className={currentItem.id === i ? "active" : ""}
            key={i}
            src={v}
            alt="image-item"
            onClick={() => setCurrentItem({ id: i, data: v })}
          />
        ))}
        {/* </div> */}
      </S.ListImage>
      <S.InfoData className="content-data">
        <S.LeftInfo>
          {/**bg={currentItem.data} */}
          {currentItem.data ? (
            <S.ArtImage
              src={currentItem.data}
              alt="art-img"
              onClick={goToDetail}
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Image"
            />
          )}
        </S.LeftInfo>
        <S.Content>
          <div className="box-content">
            <div className="box-content-item">
              {/* <div className="title">{data?.authorName},</div> */}
              <div className="title2">{data?.title}</div>
            </div>
            <div className="box-content2">
              <div>
                <div className="item-content2">
                  <span className="text-normal">1주당 금액</span>
                  <span className="text-bold text-bold2">
                    {formatPrice(data?.unitPrice)}원
                  </span>
                </div>
                <div className="item-content2">
                  <span className="text-normal">총 발행금액</span>
                  <span className="text-bold">
                    {formatPrice(data?.target)}원{" "}
                  </span>
                </div>

                <div className="text-small" style={{ textAlign: "right" }}>
                  ({formatPrice(data?.target / data?.unitPrice)}주)
                </div>
              </div>
              <div className="box-timer">
                {handleShowCountDownIsComming()}
                {/* {!timeComplete && (
                  <div className="time">
                    <span>마감까지</span>{" "}
                    <span className="time-countdown">
                      {days}일 {prefixO(hours)}시간 {prefixO(minutes)}분{" "}
                      {prefixO(seconds)}초
                    </span>{" "}
                    <span>남았습니다.</span>
                  </div>
                )} */}
                <div className="time-end">
                  (<span>청약 마감일</span> :{" "}
                  {moment(data?.endNormalBuyAt).format("YYYY.MM.DD HH:mm")})
                </div>
              </div>
              <div className="price">
                <div>누적 신청 금액</div>
                <div>
                  <span className="price-bold">
                    {formatPrice(data?.v3TotalUnitReg * data?.unitPrice)}
                  </span>{" "}
                  <span>원</span>
                </div>
              </div>
            </div>
            <div className="box-select">
              <div className="item-select">
                <div className="item-select-text">청약 수량</div>
                <InputNumberNew
                  max={99999999999999999999999999}
                  // max={Math.min(
                  //   data?.gpVer === "V3"
                  //     ? data?.stockForBuy
                  //     : data?.canOrderWaiting
                  //     ? data?.maxUnitForU1
                  //     : data?.stockForBuy,
                  //   data?.maxUnitForU1
                  // )}
                  disabled={checkDisabledSelect}
                  onChange={(e) => {
                    getQuantity(e);
                    setQty(e);
                  }}
                  value={qty}
                  handleUp={() => {
                    let value = qty + 1;
                    getQuantity(value);
                    setQty(value);
                  }}
                  handleDown={() => {
                    let value = qty - 1;
                    getQuantity(value);
                    setQty(value);
                  }}
                />
                <div className="item-select-qty">주</div>
              </div>
              {/* <div className="note-select">
                *최대 신청 가능 수량:{" "}
                {formatPrice(
                  Math.min(
                    data?.gpVer === "V3"
                      ? data?.stockForBuy
                      : data?.canOrderWaiting
                      ? data?.maxUnitForU1
                      : data?.stockForBuy,
                    data?.maxUnitForU1
                  )
                )}
              </div> */}
              <div className="box-shinhan">
                <div className="shinhan-text">
                  신청 한도 :{" "}
                  {formatPrice(
                    Math.min(
                      data?.gpVer === "V3"
                        ? data?.stockForBuy
                        : data?.canOrderWaiting
                        ? data?.maxUnitForU1
                        : data?.stockForBuy,
                      data?.maxUnitForU1
                    )
                  )}
                  주
                </div>
                {dataShinhan?.balance >= 0 && (
                  <div className="shinhan-text note-select2">
                    나의 신청가능 수량 :{" "}
                    {formatPrice(
                      Math.min(
                        Math.floor(dataShinhan?.balance / data?.unitPrice),
                        Math.min(
                          data?.gpVer === "V3"
                            ? data?.stockForBuy
                            : data?.canOrderWaiting
                            ? data?.maxUnitForU1
                            : data?.stockForBuy,
                          data?.maxUnitForU1
                        )
                      )
                    )}
                    주
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="box-total">
            <div className="title-total">총 신청 금액 :</div>
            <div className="box-total-item">
              <div className="sub-total">총 {qty}주</div>
              <div>
                <span className="price-total">
                  {formatPrice(data?.unitPrice * qty)}
                </span>
                <span className="value-price">원</span>
              </div>
            </div>
          </div>

          <S.ActionButton
            className="action-button"
            onClick={() => {
              actionButtonEvent?.();
              getTypeBuyGp?.("NORMAL");
            }}
            disabled={
              (!data?.canOrderNormal && !data?.canOrderWaiting) || timeComplete
            }
          >
            청약 신청
          </S.ActionButton>
        </S.Content>
      </S.InfoData>
    </S.SaleTogetherContainer>
  );
}

export default CardDetailOnGoing;
